.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





#editor {
  --mui-backgroundcolor: #red;
  --mui-backgroundpaper: #red;
  --scrollbar: #red;
  --scrollbar-hover: #red;
}

/* Scrollbar Styles */
::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar);
  border-radius: 4px;
  transition: background-color 0.8s ease;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--scrollbar-hover);
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

.hidden-scrollbar {
  display: none;
}

body.scrollbar-visible::-webkit-scrollbar {
  display: block;
}


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #a4a498;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
}