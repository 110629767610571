.TabViewChildItem {
  width: fit-content;
  height: 32px;
  display: flex;
  gap: 4px;
  border-radius: 16px 16px 0px 0px;
  border: solid 1px #bbb;
  cursor: pointer;
  margin-top: 8px;
  padding-left: 8px;
  padding-right: 1px;
  align-items: center;
  transition: background-color 0.075s linear;
}

.selected {}

.TabViewChildItem:not(.selected):hover {
  background-color: #ddd;
}

.invertedBorder:not(.selected)::before {
  content: '';
  width: 16px;
  position: absolute;
  left: -16px;
  bottom: 0;
  aspect-ratio: 1;
  z-index: 1;
  background-image: radial-gradient(circle at 0% 0%, transparent 16px, var(--mui-backgroundcolor) 16px);
}

.invertedBorder:not(.selected)::after {
  content: '';
  width: 16px;
  position: absolute;
  right: -16px;
  bottom: 0px;
  aspect-ratio: 1;
  z-index: 1;
  background-image: radial-gradient(circle at 100% 0%, transparent 16px, var(--mui-backgroundcolor) 16px);
}