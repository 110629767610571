.table-view-drag {
  position: relative;
  background-color: #ddd;
  cursor: pointer;
}

.table-view-drag:hover::after {
  content: ' ';
  position: absolute;
  background-color: red;
  border-radius: 100svh;
  width: 50px;
  height: 4px;
  left: 50%;
  top: -50%;
  right: auto;
}

.table-view-drag:active::after {
  content: ' ';
  position: absolute;
  background-color: red;
  border-radius: 100svh;
  width: 50px;
  height: 4px;
  left: 50%;
  top: -50%;
  right: auto;
}

.table-view-drag:hover {
  background-color: #888;
}

.table-view-drag:active {
  background-color: #888;
}

.no-scrollbar {
  overflow-x: hidden;
  overflow-y: hidden;
}

.no-scrollbar::-webkit-scrollbar:horizontal {
  height: 0px;
  background-color: red;
}

.no-scrollbar::-webkit-scrollbar-thumb:horizontal {
  background: #888;
}

.no-scrollbar::-webkit-scrollbar-thumb:horizontal:hover {
  background: #555;
}